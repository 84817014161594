import React from "react";
import logoLobas from "../../images/LogoLobas.svg";
import "./Navbar.css";

function Navbar() {
  return (
    <div className="navbar">
      <img className="navbar__logo" src={logoLobas} alt="logo navbar" />
      <div className="navbar__linksContainer">
        <a className="linksContainer__link" href="#com">
          Comunicación
        </a>
        <a className="linksContainer__link" href="#branding">
          Branding
        </a>
        <a className="linksContainer__link" href="#digital">
          Digital
        </a>
      </div>
    </div>
  );
}

export default Navbar;
