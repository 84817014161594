import React, {
  Suspense,
  lazy,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Navbar from "../Navbar/Navbar.js";
import Loading from "../Loading/Loading.js";
import fondoLoboWeb from "../../images/fondoLoboWeb.webp";
import logo from "../../images/LogoLobas.svg";
import "./Home.css";
const BlockWorks = lazy(() => import("../BlockWorks/BlockWorks.js"));
const BlockTeam = lazy(() => import("../BlockTeam/BlockTeam.js"));
const Footer = lazy(() => import("../Footer/Footer.js"));
const HeroBanner = lazy(() => import("../HeroBanner/HeroBanner.js"));

function Home() {
  const year = new Date().getFullYear();
  const [position, setPosition] = useState(0);
  const [colorClass, setcolorClass] = useState("");
  const scrollArrow = useRef();

  useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.scrollY);
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  useEffect(() => {
    if (position >= 380) {
      scrollArrow.current.style.display = "flex";
    } else {
      scrollArrow.current.style.display = "none";
    }
    if (position <= 800) {
      setcolorClass("");
    }
    if (position >= 800) {
      setcolorClass("scrollUpMagenta");
    }
    if (position >= 1230) {
      setcolorClass("scrollUpCyan");
    }
    if (position >= 2250) {
      setcolorClass("scrollUpMagenta");
    }
    if (position >= 3720) {
      setcolorClass("");
    }
  }, [position]);

  return (
    <div className="home" id="home">
      <a
        href="#home"
        className={`scrollUpContainer ${colorClass}`}
        ref={scrollArrow}
      >
        <svg
          className="scrollUp"
          width="56"
          height="105"
          viewBox="0 0 16 65"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className={`scrollUpPath ${colorClass}`}
            d="M8.7071 0.292893C8.31658 -0.0976311 7.68341 -0.0976311 7.29289 0.292893L0.92893 6.65685C0.538405 7.04738 0.538405 7.68054 0.92893 8.07107C1.31945 8.46159 1.95262 8.46159 2.34314 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.7071 0.292893ZM9 65L9 1L7 1L7 65L9 65Z"
            strokeWidth="2"
            stroke="black"
          />
        </svg>
        <p className={`scrollTextUp ${colorClass}`}>Up</p>
      </a>
      <div className="homeViewHeightContainer">
        <Navbar />
        <img className="logoLobas" src={logo} alt="logo Lobas" />
        <img
          className="homeWallpaper"
          src={fondoLoboWeb}
          alt="wallpaper lobo"
        />
        <svg
          className="scrollDownContainer"
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="15.0163"
            y="3.98347"
            width="34"
            height="58"
            rx="17"
            transform="rotate(-0.939191 15.0163 3.98347)"
            stroke="#e71d75"
            strokeWidth="2"
          />
          <path
            id="pathScrollDown"
            d="M31.6464 50.3536C31.8417 50.5488 32.1583 50.5488 32.3536 50.3536L35.5355 47.1716C35.7308 46.9763 35.7308 46.6597 35.5355 46.4645C35.3403 46.2692 35.0237 46.2692 34.8284 46.4645L32 49.2929L29.1716 46.4645C28.9763 46.2692 28.6597 46.2692 28.4645 46.4645C28.2692 46.6597 28.2692 46.9763 28.4645 47.1716L31.6464 50.3536ZM31.5 14L31.5 50L32.5 50L32.5 14L31.5 14Z"
            fill="#e71d75"
          />
        </svg>
      </div>
      <div className="blockServices blockServices--com" id="com">
        <h1 className="blockServices__title blockServices__title--com">
          Comunicación
        </h1>
        <div className="blockServices__subtitleContainer subtitleContainer--com">
          <h5 className="blockServices__subtitle blockServices__subtitle--com">
            Creatividad
          </h5>
          <h5 className="blockServices__subtitle blockServices__subtitle--com">
            Campañas publicitarias
          </h5>
          <h5 className="blockServices__subtitle blockServices__subtitle--com">
            Diseño y desarrollo de sitios web
          </h5>
        </div>
      </div>
      <div className="blockServices blockServices--branding" id="branding">
        <h1 className="blockServices__title blockServices__title--branding">
          Branding
        </h1>
        <div className="blockServices__subtitleContainer subtitleContainer--branding">
          <h5 className="blockServices__subtitle blockServices__subtitle--branding">
            Diseño
          </h5>
          <h5 className="blockServices__subtitle blockServices__subtitle--branding">
            Naming
          </h5>
          <h5 className="blockServices__subtitle blockServices__subtitle--branding">
            Imagen corporativa
          </h5>
          <h5 className="blockServices__subtitle blockServices__subtitle--branding">
            Identidad
          </h5>
        </div>
      </div>
      <div className="blockServices blockServices--digital" id="digital">
        <h1 className="blockServices__title blockServices__title--digital">
          Digital
        </h1>
        <div className="blockServices__subtitleContainer subtitleContainer--digital">
          <h5 className="blockServices__subtitle blockServices__subtitle--digital">
            Estrategia de contenido
          </h5>
          <h5 className="blockServices__subtitle blockServices__subtitle--digital">
            Ecosistema
          </h5>
          <h5 className="blockServices__subtitle blockServices__subtitle--digital">
            SEO/SEM
          </h5>
          <h5 className="blockServices__subtitle blockServices__subtitle--digital">
            Marketing y campañas digitales
          </h5>
        </div>
      </div>
      <Suspense fallback={<Loading />}>
        <HeroBanner />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <BlockWorks />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <BlockTeam />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <Footer />
      </Suspense>
      <p className="copyright">© {year} Lobo</p>
    </div>
  );
}

export default Home;
